import React from 'react';
import './WalletMenu.css';

const WalletMenu = ({ setWalletAPI, connectedWalletAPI,setWalletName,onClose}) => {
  let walletAPI,myOrders;
  const isNamiAvailable = window.cardano?.nami !== undefined;
  const isEternlAvailable = window.cardano?.eternl !== undefined;
  const isFlintAvailable = window.cardano?.flint !== undefined;
  const isTyphonAvailable = window.cardano?.typhon !== undefined;


  return (
    <div className="backdrop" onClick={onClose}>
    <div className="wallet-menu">
      <h2 style={{textAlign:"center"}}>Connect Wallet</h2>
      <ul className="wallet-list">
        {isNamiAvailable && (
          <li className="wallet-item">
            <button onClick={async () => {walletAPI = await window.cardano.nami.enable();setWalletAPI(walletAPI);connectedWalletAPI=walletAPI;setWalletName('Nami');}}>
                <img src={window.cardano.nami.icon} alt="Nami Icon" className="wallet-icon" />
                Nami
            </button>
          </li>
        )}
        {isEternlAvailable && (
          <li className="wallet-item">
            <button onClick={async () => {walletAPI = await window.cardano.eternl.enable();setWalletAPI(walletAPI);connectedWalletAPI=walletAPI;setWalletName('Eternl');}}>
                <img src={window.cardano.eternl.icon} alt="Eternl Icon" className="wallet-icon" />
                Eternl
            </button>
          </li>
        )}
        {isFlintAvailable && (
          <li className="wallet-item">
            <button onClick={async () => {walletAPI = await window.cardano.flint.enable();setWalletAPI(walletAPI);connectedWalletAPI=walletAPI;setWalletName('Flint');}}>
                <img src={window.cardano.flint.icon} alt="Flint Icon" className="wallet-icon" />
                Flint
            </button>
          </li>
        )}
        {isTyphonAvailable && (
          <li className="wallet-item">
            <button onClick={async () => {walletAPI = await window.cardano.typhon.enable();setWalletAPI(walletAPI);connectedWalletAPI=walletAPI;setWalletName('Typhon');}}>
                <img src={window.cardano.typhon.icon} alt="Typhon Icon" className="wallet-icon" />
                Flint
            </button>
          </li>
        )}
      </ul>
      {(!isNamiAvailable & !isEternlAvailable & !isFlintAvailable & !isTyphonAvailable)?
      <>
      <div>
      This browser does not have Nami, Eternl, Typhon or Flint wallets installed.
      </div>
      <br/>
      </>
      :
      <>
      </>
      }
    </div>
    </div>
  );
};

export default WalletMenu;
