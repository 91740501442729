// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Update the import statement
import { Lucid, Blockfrost } from 'lucid-cardano';
import Cookies from 'js-cookie';
import { WEBSITE } from './Constants';

import './App.css';

// Use the Lucid object in your component


import Header from './components/Header';
import Home from './pages/Home';
import Tip from './pages/Tip';
import MessageWindow from './components/MessageWindow';


let lucid;
switch(WEBSITE){
  case 'https://test-orderbook.adalink.io':
    lucid = await Lucid.new(
      new Blockfrost("https://cardano-preview.blockfrost.io/api/v0", "preview7iVl38anG9Np8lT4JzXCKB16mxPC8Kyg"),
      "Preview",
    );
  break;
  case 'https://orderbook.adalink.io':
    lucid = await Lucid.new(
      new Blockfrost("https://cardano-mainnet.blockfrost.io/api/v0", "mainnetedOr1A0jt3OG6NJ4dI0U59cFb42hgD3t"),
      "Mainnet",
    );
    
  break;
}





//allPairs = [{name:"TokenA-tADA",policy_id:"ccc33d8b674ebeff713436985c513e80ee3c3eecfab11378ea144dd8",token_name:"TokenA",decimals:6,icon:tokenALogo}
//                  , {name:"TokenB-tADA",policy_id:"6a25d74d073fc021944ddc109b8d61a77dc636133e2ed295ff59ae3e",token_name:"TokenB",decimals:0,icon:tokenBLogo}];

let checkForLastConnectedWalletEnabled = true;
let connectedWalletAPI;


function App() {

  const [selectedPair, setSelectedPair] = useState(); // State for selected pair

  const [walletAPI,setWalletAPI]=useState();
  const [walletName,setWalletName]=useState();
  const [walletID,setWalletID]=useState("-1");
  
  const [showMessageWindow,setShowMessageWindow] = useState(false);
  const [messageWindowContent,setMessageWindowContent] = useState('');
  const [messageWindowButtonText,setMessageWindowButtonText] = useState('Ok');

  


  useEffect(() => {
    // Your code here

    const intervalId = setInterval(() => {
      // This code will run every 5 seconds
    }, 5000);

    // Don't forget to clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [selectedPair]); // Empty dependency array to run the effect only once

  useEffect(() => {
    // Fetch orders when selectedPair changes
    
  }, [selectedPair]);

  useEffect(() => {
    //this will run once!
    if (checkForLastConnectedWalletEnabled) {
      connectLastConnectedWallet();
      checkForLastConnectedWalletEnabled=false
    }

    
  },[])


  async function connectLastConnectedWallet(){
    /*
    if(Cookies.get('lastConnectedWalletName')){
      //console.log('checking last connected wallet...');
      let lastConnectedWalletName = Cookies.get('lastConnectedWalletName');
      let lastConnectedWalletAPI;
      switch(lastConnectedWalletName){
        case 'nami':
          lastConnectedWalletAPI = await window.cardano.nami.enable();
          setWalletName('Nami');
        break;
        case 'eternl':
          lastConnectedWalletAPI = await window.cardano.eternl.enable();
          setWalletName('Eternl');
        break;
        case 'flint':
          lastConnectedWalletAPI = await window.cardano.flint.enable();
          setWalletName('Flint');
        break;
      }
      
      setWalletAPI(lastConnectedWalletAPI);
      
      connectedWalletAPI=lastConnectedWalletAPI;
      Cookies.set('lastConnectedWalletName',lastConnectedWalletName,{expires:1000});
      lucid.selectWallet(lastConnectedWalletAPI);
      let stakeAddress = await lucid.wallet.rewardAddress();
      let response = await fetch("https://adalink.io/api/is-wallet-registered.php?address="+stakeAddress,{cache:"reload"});
      let walletStatus = await response.text();
      setWalletID(walletStatus);
      
    }
    */
  }


  return (
    <Router>
      <div className="App">
        <Header walletAPI={walletAPI} setWalletAPI={setWalletAPI} connectedWalletAPI={connectedWalletAPI}  setWalletName={setWalletName} Cookies={Cookies}/>
        <Routes>
          <Route path="/"
            element={
              <Home
                walletAPI={walletAPI} setWalletAPI={setWalletAPI} connectedWalletAPI={connectedWalletAPI} walletName={walletName} setWalletName={setWalletName} Cookies={Cookies}
                walletID={walletID} setWalletID={setWalletID}
                lucid={lucid}
                setMessageWindowContent={setMessageWindowContent}
                setMessageWindowButtonText={setMessageWindowButtonText}
                setShowMessageWindow={setShowMessageWindow}
              />} />
          <Route path="/tip"
            element={
              <Tip
                walletAPI={walletAPI} setWalletAPI={setWalletAPI} connectedWalletAPI={connectedWalletAPI} walletName={walletName} setWalletName={setWalletName} Cookies={Cookies}
                walletID={walletID} setWalletID={setWalletID}
                lucid={lucid}
                setMessageWindowContent={setMessageWindowContent}
                setMessageWindowButtonText={setMessageWindowButtonText}
                setShowMessageWindow={setShowMessageWindow}
              />} />
        </Routes>
        {showMessageWindow && ( // Show OrderSummary when orderSummaryVisible is true
          <MessageWindow
            message={messageWindowContent}
            buttonText={messageWindowButtonText}
            onClose={() => setShowMessageWindow(false)} // Close OrderSummary
            onAction={ () => setShowMessageWindow(false)} // Pass placeOrderHandler as onPlaceOrder
          />
        )}
        
      </div>
    </Router>
  );
}

export default App;

