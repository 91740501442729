// src/components/Header.js
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import WalletMenu from './WalletMenu'; 
import logo from '../assets/images/adalinklogo.png';

import './Header.css'




function Header({walletAPI,setWalletAPI,connectedWalletAPI,walletName,setWalletName,orders,setMyOrders,initalizeMyOrders,Cookies}) {


  const [isWalletMenuOpen, setWalletMenuOpen] = useState(false);

  const handleOpenWalletMenu = () => {
    setWalletMenuOpen(true);
  };
  
  const handleCloseWalletMenu = () => {
    setWalletMenuOpen(false);
  };


  /*
            <li className="nav-item">
            <Link to="/revenue-share-offering">Revenue Share Offering</Link>
          </li>
  */

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={logo} width={144} alt="Your Logo" />
        </Link>
      </div>
      <div className='preview-badge'>Preview Net</div>
    </header>
  );
}

export default Header;
