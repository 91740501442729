// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import the CSS file
import './Tip.css';
import WalletMenu from '../components/WalletMenu'; 
import {FEE_ADDRESS,PUBLIC_FEE_ADDRESS,NFT_PRICE,WL_END_TIME_POSIX,WL_COLLECTOR_ADDRESS} from '../Constants.js';
import { useSearchParams } from 'react-router-dom';

const queryParameters = new URLSearchParams(window.location.search);
let tipWalletID = queryParameters.get('SiEq');
let affiliatID = queryParameters.get('AffEq');
let response;
if (tipWalletID?.length>5){
  tipWalletID = tipWalletID.substring(5);
  response = await fetch("https://adalink.io/api/get-preview-address-from-wallet-id.php?walletID="+tipWalletID,{cache:"reload"});
}else{
  response = await fetch("https://adalink.io/api/get-preview-address-from-affiliate-id.php?affiliateID="+affiliatID,{cache:"reload"});
}

let tipAddress = await response.text();

function Home({walletAPI,setWalletAPI,connectedWalletAPI,setWalletName,walletID,setWalletID,lucid,setMessageWindowContent,setMessageWindowButtonText,setShowMessageWindow}) {

  const [adaPaymentAmount,setAdaPaymentAmount] = useState("10");

  const [isWalletMenuOpen, setWalletMenuOpen] = useState(false);
  
  

  useEffect(() => {
    // Your code here

    const intervalId = setInterval(() => {
      // This code will run every 5 seconds
      
    }, 1000);

    // Don't forget to clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array to run the effect only once

  const handleOpenWalletMenu = () => {
    
    setWalletMenuOpen(true);
  };
  
  const handleCloseWalletMenu = () => {
    
    setWalletMenuOpen(false);
  };

  const buildOrderHandler = async () => {

    setMessageWindowContent('Checking...');
    setMessageWindowButtonText('');
    setShowMessageWindow(true);
    
    if(walletAPI == undefined){
      setMessageWindowContent('Connect your wallet first');
      setMessageWindowButtonText('Ok');
      setShowMessageWindow(true);
      return;
    }

    if(await walletAPI.getNetworkId() != 0){
      //setMessageWindowContent('Make sure to set your wallet on Mainnet');
      setMessageWindowContent('Make sure to set your wallet on Preview Net');
      setMessageWindowButtonText('Ok');
      setShowMessageWindow(true);
      return;
    }

    if (parseInt(adaPaymentAmount)<5){
      setMessageWindowContent('Minimum tip amount is 5 ADA.');
      setMessageWindowButtonText('Ok');
      setShowMessageWindow(true);
      return;
    }
    

    lucid.selectWallet(walletAPI);
    let tx = lucid.newTx();
    
    setMessageWindowContent('Building Transaction...');
    setMessageWindowButtonText('');
    let adalinkAddress = "addr_test1qz3q025xd9ugypwr4vad80t2snvyaq2ym943wpcugcw0992xnhwkwn5fkw3v0sj8u65glsql7sjqwwr7379rxszatsys5vq3ff";
    //"addr1q9gm0fvmsf7m9k56mru7nmw2t4waq3pfjptqkld3na7qsex6pwg9qd5jxx4c907ehvprpq38nfznppqmj76fm3cjf0hsk58xwa";
    tx.payToAddress(adalinkAddress,{lovelace:"1000000"});
    tx.payToAddress(tipAddress,{lovelace:(parseInt(adaPaymentAmount)-1)+"000000"});

    try{
      tx = await tx.complete();
    }
    catch(e){
      if (e == "InputsExhaustedError"){
        setMessageWindowContent("Wallet does not have enough ADA.");
        setMessageWindowButtonText('Ok');
        setShowMessageWindow(true);
        return;
      }
    }
    try{
      const signedTx = await tx.sign().complete();
      const txHash = await signedTx.submit();
      //console.log(txHash);
      setMessageWindowContent(<><div>Transaction has been submitted to chain.</div><div style={{fontSize:"x-small",marginTop:"5px"}}>Tx Hash: {txHash}</div></>);
      setMessageWindowButtonText('Ok');
      setShowMessageWindow(true);
    }catch{
      setMessageWindowContent("User declined transaction.");
      setMessageWindowButtonText('Ok');
      setShowMessageWindow(true);
    }
  }

  const handleAdaAmountSelectingEvent = (amountBox) => {

    document.getElementById(amountBox).classList.add('selectedBox');
    if (amountBox == '_5AdaBox'){
      setAdaPaymentAmount("5");
      document.getElementById("_10AdaBox").classList?.remove('selectedBox');
      document.getElementById("_20AdaBox").classList?.remove('selectedBox');
      document.getElementById("customAdaAmount").classList?.remove('selectedBox');
    } else if (amountBox == '_10AdaBox'){
      setAdaPaymentAmount("10");
      document.getElementById("_5AdaBox").classList?.remove('selectedBox');
      document.getElementById("_20AdaBox").classList?.remove('selectedBox');
      document.getElementById("customAdaAmount").classList?.remove('selectedBox');
    } else if (amountBox == '_20AdaBox'){
      setAdaPaymentAmount("20");
      document.getElementById("_10AdaBox").classList?.remove('selectedBox');
      document.getElementById("_5AdaBox").classList?.remove('selectedBox');
      document.getElementById("customAdaAmount").classList?.remove('selectedBox');
    } else {
      
      document.getElementById("_10AdaBox").classList?.remove('selectedBox');
      document.getElementById("_20AdaBox").classList?.remove('selectedBox');
      document.getElementById("_5AdaBox").classList?.remove('selectedBox');
    }
  };

  return (
    <div className="home">
      <div className="">
        <div className='main-box'>
          <div className='connect-box'>
            <div style={{fontSize:24}}>Tip a Creator</div>
            <div style={{textAlign:"left"}}>Thank you for helping growing Cardano by supporting its creators!</div>
            <div style={{textAlign:"left"}}>Tipping Address: <Link to={"https://preview.cardanoscan.io/address/"+tipAddress} target="_blank">{tipAddress.substring(0,8)+"...."+tipAddress.slice(-8)}</Link></div>
            {walletAPI ?
              <>
                <div>
                  <div className='adaAmounts'>
                    <button className='selectableAdaAmountBox' id='_5AdaBox' onClick={() => handleAdaAmountSelectingEvent('_5AdaBox')}>5 ADA</button>
                    <button className='selectableAdaAmountBox selectedBox'id='_10AdaBox' onClick={() => handleAdaAmountSelectingEvent('_10AdaBox')}>10 ADA</button>
                    <button className='selectableAdaAmountBox' id='_20AdaBox' onClick={() => handleAdaAmountSelectingEvent('_20AdaBox')}>20 ADA</button>
                    <input className='customAdaAmountBox' id='customAdaAmount' placeholder='Other' type='number' 
                      onClick={() => {handleAdaAmountSelectingEvent('customAdaAmount')}}
                      onChange={() => {
                                let customAmount = parseInt(document.getElementById("customAdaAmount").value).toString();
                                console.log(customAmount)
                                setAdaPaymentAmount(customAmount);}}/>
                  </div>
                  <button className='connect-button' onClick={buildOrderHandler}>Tip</button>
                  <div className='disconnect-link' onClick={() => {setWalletAPI()}}>Disconnect wallet</div>
                </div>
              </>
              :
              <>
              <button className='connect-button' onClick={handleOpenWalletMenu}>Connect wallet</button>
              </>
            }
            
          </div>
        </div>
      </div>
      { isWalletMenuOpen && <WalletMenu setWalletAPI={setWalletAPI} connectedWalletAPI={connectedWalletAPI} setWalletName={setWalletName} onClose={handleCloseWalletMenu}/> }
    </div>
  );
}

export default Home;
