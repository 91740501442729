// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import './Home.css'; // Import the CSS file
import WalletMenu from '../components/WalletMenu'; 
import {FEE_ADDRESS,PUBLIC_FEE_ADDRESS,NFT_PRICE,WL_END_TIME_POSIX,WL_COLLECTOR_ADDRESS} from '../Constants.js';





function Home({walletAPI,setWalletAPI,connectedWalletAPI,setWalletName,walletID,setWalletID,lucid,setMessageWindowContent,setMessageWindowButtonText,setShowMessageWindow}) {

  const [adaPaymentAmount,setAdaPaymentAmount] = useState("50");

  const [isWalletMenuOpen, setWalletMenuOpen] = useState(false);
  

  useEffect(() => {
    // Your code here

    const intervalId = setInterval(() => {
      // This code will run every 5 seconds
      
    }, 1000);

    // Don't forget to clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array to run the effect only once

  const handleOpenWalletMenu = () => {
    setWalletMenuOpen(true);
  };
  
  const handleCloseWalletMenu = () => {
    
    setWalletMenuOpen(false);
  };

  const handleGenerateLinkQuery = async () => {

    setMessageWindowContent('Checking...');
    setMessageWindowButtonText('');
    setShowMessageWindow(true);

    if(walletAPI == undefined){
      setMessageWindowContent('Connect your wallet first');
      setMessageWindowButtonText('Ok');
      setShowMessageWindow(true);
      return;
    }

    if(await walletAPI.getNetworkId() != 0){
      //setMessageWindowContent('Make sure to set your wallet on Mainnet');
      setMessageWindowContent('Make sure to set your wallet on Preview Net');
      setMessageWindowButtonText('Ok');
      setShowMessageWindow(true);
      return;
    }

    lucid.selectWallet(walletAPI);
    let stakeAddress = await lucid.wallet.rewardAddress();

    let response = await fetch("https://adalink.io/api/is-preview-wallet-registered.php?address="+stakeAddress,{cache:"reload"});
    let walletStatus = await response.text();
    switch(walletStatus){
      case "-1":
        //wallet is not previosuly registered, you can register it
        setMessageWindowContent('Generating link...');
        setMessageWindowButtonText('');
        break;
      default:
        setMessageWindowContent('This wallet is already registered.');
        setMessageWindowButtonText('Ok');
        setShowMessageWindow(true);
        setWalletID(walletStatus);
        return;
    }

    let paymentAddress = await lucid.wallet.address();
    response = await fetch("https://adalink.io/api/register-preview-wallet-for-tipping.php?address="+paymentAddress+"&stakeAddress="+stakeAddress,{cache:"reload"});
    let responseID = await response.text();
    setWalletID(responseID);
    setMessageWindowContent('Success!');
    setMessageWindowButtonText('');
    setShowMessageWindow(false);
  }
  

  return (
    <div className="home">
      <div className="">
        <div className='main-box'>
          <div className='connect-box'>
            <div style={{fontSize:24}}>Create a Tip Link</div>
            <div style={{textAlign:"left"}}>Connect, submit then share the link with others. It's that simple!</div>
            {walletAPI ?
              <>
                {walletID == "-1"?
                <>
                  <div style={{textAlign:"left"}}>Step 2 of 3: Generate url link.</div>
                  <div >
                  <button className='connect-button' onClick={handleGenerateLinkQuery}>Generate tipping link</button>
                  <div className='disconnect-link' onClick={() => {setWalletAPI();setWalletID("-1")}}>Disconnect wallet</div>
                  </div>
                </>
                :
                <>
                  <div style={{textAlign:"left"}}>Tipping link: https://tip-preview.adalink.io/tip?SiEq=62245{walletID}</div>
                  <div>
                  <button className='connect-button' onClick={() => {
                    navigator.clipboard.writeText("https://tip-preview.adalink.io/tip?SiEq=62245"+walletID);
                    setMessageWindowContent("Link copied.");
                    setMessageWindowButtonText("Ok");
                    setShowMessageWindow(true);
                    }}>Copy link</button>
                  <div className='disconnect-link' onClick={() => {setWalletAPI();setWalletID("-1")}}>Disconnect wallet</div>
                  </div>
                </>
                }
              
              </>
              :
              <>
              <div style={{textAlign:"left"}}>Step 1 of 3: Connect your wallet of choice.</div>
              <button className='connect-button' onClick={handleOpenWalletMenu}>Connect wallet</button>
              </>
            }
            
          </div>
        </div>
      </div>
      { isWalletMenuOpen && <WalletMenu setWalletAPI={setWalletAPI} connectedWalletAPI={connectedWalletAPI} setWalletName={setWalletName} onClose={handleCloseWalletMenu}/> }
    </div>
  );
}

export default Home;
